<template>
  <div class="inputBoxWrap">
    <label>
      <span class="label">{{label}}</span>
      <span  class="errMsg" v-if="errMsg.length > 0">{{errMsg}}</span>
      <suggestions
        v-model="value"
        :options="options"
        :onInputChange="onInputChange"
        :onItemSelected="onSearchItemSelected"
      >

        <div slot="item" slot-scope="props" class="single-item">
          <span class="name">{{props.item.value}}</span>
        </div>
      </suggestions>
    </label>
  </div>
</template>

<script>
  import 'v-suggestions/dist/v-suggestions.css';

  export default {
    name: "vmSelectDadata",
    props:{
      refs: {
        type: String,
        default: 'null'
      },
      label:{
        type:String,
        default: '',
      },
      errMsg:{
        type:String,
        default:'',
      }

    },
    data() {
      return {
        query:'',
        value:'',
        selectedCountry: null,
        selectedSearchItem: null,
        options: {
          placeholder: '',
        },
        item: {},
      }
    },
    computed:{

    },
    components: {
      suggestions:()=>import('v-suggestions')
    },
    methods:{
      test(){
        console.log('asd');
      },
      onInputChange (query) {
        if (query.trim().length === 0) {
          return null
        }
        // return the matching countries as an array
        return new Promise(resolve => {
          this.$myApi.singleRequest({
            action:'Dadata_getBank',
            value:query,
          }).then(resp => {
            let dadataSuggest = resp.suggestions;
            let result = (Array.isArray(dadataSuggest) && dadataSuggest.length) ? dadataSuggest : [];
            resolve(result);
          });
        });
      },
      onSearchItemSelected (item) {
        this.selectedSearchItem = item;
        this.value = this.query = item.value;
        this.$emit('setbank', this.selectedSearchItem);
      },

    },

  }
</script>

<style scoped>

  /*.inputBoxWrap{background: #F6F8F7;padding: 3vw 3vw 2vw;border-radius: 1px;}*/
  /*.inputBoxWrap label{display: block;color:#869A95;font-size:  4vw;}*/
  /*/deep/input{display: block;color:#222721;font-size: 5vw;padding: 1.6vw 0*/
  /*;background: transparent;border: 0;width: 100%;}*/
  /*.inputBoxWrap p{display: block;}*/
  /deep/.v-suggestions .items .item.is-active{background: #43AC35;color:white}
  /deep/.v-suggestions .items .item:hover{color:#0D5A44;}

  /*@media screen and (min-width: 760px) {*/
  /*  .inputBoxWrap{margin-bottom: 0.6vw;padding: 1.5vw 1.1vw 1vw;}*/
  /*  .inputBoxWrap label{font-size: 1.1vw;}*/
  /*  /deep/input{font-size: 1.3vw;padding: 0.2vw 0;}*/
  /*  .inputBoxWrap p{}*/
  /*}*/
  /*@media screen and (min-width: 1140px) {*/
  /*  .inputBoxWrap{padding: 17px 13px 11px;margin-bottom: 7px;}*/
  /*  .inputBoxWrap label{font-size:  12px;}*/
  /*  /deep/input{font-size: 14px;padding: 2px 0;}*/
  /*  .inputBoxWrap p{}*/
  /*}*/
</style>
